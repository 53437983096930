import GlobalLayout from "components/page/GlobalLayout";
import SensorSpecsPage from "components/sensors/SensorSpecsPage";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function D1() {
  const renderContent = data => {
    const sensor = {
      title: "D20",
      imageHeader: data.d20Header,
      imageHeaderMobile: data.d20HeaderMobile,
      image: data.d20Dims,
      objectPosition: "90%",
      description:
        "Get real-time awareness, instant alerts, and synchronized video clips for entry events. Manage entry points during emergencies and prevent unauthorized access.",
      pdf: "/files/specs/D20.pdf",
      column1: [
        {
          title: "Dimensions",
          text: "77mm (3.03in) x 27mm (1.06in) x 27.7mm (1.09in)",
        },
        {
          title: "Range",
          text: "Up to 100m (328ft)",
        },
        {
          title: "Battery Type",
          text: "Replaceable 1x3.6V ER14505 (Lithium Battery included)",
        },
        {
          title: "Battery Life",
          text: "Up to 8 years with typical medium-heavy use",
        },
        {
          title: "Weight",
          text: "50g (w/ battery)",
        },
      ],
      column2: [
        {
          title: "Mounting",
          text: "Two-sided tape (included) or screws (included)",
        },
        {
          title: "Weather Resistance",
          text: "IPX4",
        },
        {
          title: "Magnet Sensing Distance",
          text: "Up to 20mm (0.78in)",
        },
        {
          title: "Magnet Dimensions",
          text: "40mm (1.57in) x 15mm (0.59in) x 15mm (0.59in)",
        },
        {
          title: "NDAA, FCC, CE, REACH, RoHS, IC, and RCM Compliant",
        },
        {
          title: "2-Year Warranty Included",
        },
      ],
    };

    return (
      <GlobalLayout color="transparent" dark>
        <Helmet>
          <title>Rhombus D20 Entry Sensor - Enhanced Entry Monitoring</title>
          <meta
            name="description"
            content="Get real-time awareness, instant alerts, and synchronized video clips for entry events."
          />
          <meta
            name="thumbnail"
            content="https://rhombus.com/img/d20_b-1.png"
          />
        </Helmet>
        <SensorSpecsPage sensor={sensor} />
      </GlobalLayout>
    );
  };

  const GET_IMAGES = graphql`
    query {
      d20Header: file(
        relativePath: { eq: "components/sensors/img/d20-header-2000-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      d20HeaderMobile: file(
        relativePath: {
          eq: "components/sensors/img/d20-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      d20Dims: file(
        relativePath: { eq: "components/sensors/img/d20-dims-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
