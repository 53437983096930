import React from "react";

import SensorSpecsHeader from "./SensorSpecsHeader";
import SensorSpecs from "./SensorSpecs";
import TrialBanner from "components/common/footer-banners/TrialBanner";

export default function SensorSpecsPage({ sensor }) {
  const {
    title,
    imageHeader,
    imageHeaderMobile,
    description,
    objectPosition,
  } = sensor;

  return (
    <>
      <SensorSpecsHeader
        title={title}
        description={description}
        webHeader={imageHeader}
        mobileHeader={imageHeaderMobile}
        objectPosition={objectPosition}
        includePricingLink={true}
      />
      <SensorSpecs sensor={sensor} />
      <TrialBanner />
    </>
  );
}
