import { css } from "linaria";
import React from "react";
import RoundLink from "components/common/RoundLink";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import PageContent from "components/page/PageContent";
import RhombusButton from "components/common/RhombusButton";
import {
  MainParagraph,
  PageTitle,
} from "components/rhombus-UI/theme/typography";
import BackLink from "components/common/BackLink";

const sectionClass = css`
  width: 100%;
  height: calc(100vh);
  display: grid;
  margin-top: -80px;
  background-color: var(--blue-900);
  @media (max-width: 600px) {
    height: 500px;
  }
`;

const contentWrapper = css`
  display: grid;
  grid-area: 1/1;
  place-items: center;
  position: relative;
`;

const contentClass = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  color: var(--white);
  margin: 0 auto;
  animation: fadein 2.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @media (max-width: 700px) {
    justify-content: flex-end;
    padding-bottom: 80px;
  }

  p {
    max-width: 445px;
  }
`;

const headerContainer = css`
  display: flex;
  flex-direction: column;
`;

const linkContainer = css`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-top: 0.5rem;
`;

const buttonStyle = css`
  font-size: 14px;
`;

const pageContentClass = css`
  height: 100%;
`;

export default function SensorSpecsHeader({
  title,
  description,
  webHeader,
  mobileHeader,
  objectPosition,
  includePricingLink,
  accessControlCTA = false,
}) {
  const pricingLink = `/pricing/sensors/?model=${title.toLowerCase()}`;
  const images = withArtDirection(getImage(webHeader), [
    {
      media: "(max-width: 700px)",
      image: getImage(mobileHeader),
    },
  ]);

  return (
    <section className={sectionClass}>
      <GatsbyImage
        image={images}
        alt=""
        style={{
          gridArea: "1/1",
          pointerEvents: "none",
          opacity: 0.6,
        }}
        loading="eager"
        objectPosition={objectPosition}
      />
      <div className={contentWrapper}>
        <PageContent contentClass={pageContentClass}>
          <div className={contentClass}>
            <BackLink
              text={
                accessControlCTA
                  ? "All Access Control Devices"
                  : `Browse All Sensors`
              }
              path={
                accessControlCTA ? "/access-control/#cards" : `/sensors/#cards`
              }
              color="var(--white)"
            />
            <div className={headerContainer}>
              <PageTitle>{title}</PageTitle>
            </div>
            <MainParagraph>{description}</MainParagraph>
            <div className={linkContainer}>
              <RhombusButton
                type="white"
                title={accessControlCTA ? "Request Demo" : "Start Trial"}
                path={
                  accessControlCTA ? "/lp/access-control-a/" : "/free-trial/"
                }
              />
              {includePricingLink && (
                <RhombusButton
                  type="white-outlined"
                  path={pricingLink}
                  title="View Pricing"
                />
              )}
            </div>
          </div>
        </PageContent>
      </div>
    </section>
  );
}
